import React from 'react';
import { navigate } from 'gatsby';
import { Button } from 'react-bootstrap';
import loadable from '@loadable/component';

import star from '../assets/star.png';
import num_1 from '../assets/num_1.png';
import num_2 from '../assets/num_2.png';
import num_3 from '../assets/num_3.png';
import MainLayout from '../layouts/mainLayout';
import { OptimizedImage } from '../components/common/OptimizedImage';
import { isLoggedIn } from '../services/auth.api';
import InfoItemsCard from '../components/common/InfoItemsCard/InfoItemsCard';
import CardWrapper from '../components/Home/CardWrapper';

const JoinFurFamily = loadable(() => import('components/JoinFurFamily'));
const HowItWorksSection = loadable(() =>
  import('components/Home/HowItWorksSection'),
);

const infoItems = [
  {
    imgSrc: num_1,
    imgAlt: 'Step 1',
    imgTitle: 'Introduce your Furbaby',
    title: 'Introduce your Furbaby',
    description:
      'Your dog’s Age, Weight and Activity level will tell us how many calories a day they need.',
  },
  {
    imgSrc: num_2,
    imgAlt: 'Step 2',
    imgTitle: 'Design Feeding Plan',
    title: 'We’ll design their feeding plan',
    description:
      'Based on the optimal daily diet for your specific dog, we will create a personalised daily meal plan.',
  },
  {
    imgSrc: num_3,
    imgAlt: 'Step 3',
    imgTitle: 'Start Subscription',
    title: 'Start your subscription',
    description:
      'Your dog’s daily meal requirements will determine how much and how often they need an order.',
  },
];

export default () => {
  return (
    <MainLayout withTitle>
      <div className="banner hiw-image hiw-banner">
        {/* <OptimizedImage fileName="banner_bg_3.png" /> */}
        <div className="container-fluid">
          <div className="banner-content">
            <h5>
              Buy your dog food online and we deliver it to your doorstep.
            </h5>
            <h2>
              Fabulous, natural dog food,
              <br /> delivered right to your door!
            </h2>
            {!isLoggedIn() && (
              <Button
                className="btn-def"
                onClick={() => navigate('/getstarted/')}
              >
                Build your feeding plan
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="ingr-col-wrapper no-mgn-bot">
        <div className="container-fluid">
          <div className="def-w-max">
            <div className="ing-hdr-wrap text-center">
              <h2 className="mgn-bot-20">Never think about buying dog food again</h2>
              <p>
                Enjoy the convenience of home delivery, with the flexibility of a plan made just for your dog
                <br />
                Your new box of dog food will arrive just before you run out... and you won’t even have to think about it.
              </p>
            </div>
            <div className="how-wrapper text-center">
              <div className="row">
                {infoItems.map((item, index) => (
                  <InfoItemsCard
                    key={index}
                    imgSrc={item.imgSrc}
                    imgAlt={item.imgAlt}
                    imgTitle={item.imgTitle}
                    title={item.title}
                    description={item.description} />
                ))}
              </div>
              <div className="col-12">
                <Button
                  className="btn-def mgn-top-30"
                  onClick={() =>
                    navigate(isLoggedIn() ? '/account/dashboard/' : '/getstarted/')
                  }
                >
                  {isLoggedIn() ? 'Dashboard' : 'Build your feeding plan'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="features-wrapper mgn-sm-top-100">
        <div className="container-fluid">
          <div className="teat-bg">
            <div className="section-item def-w-max">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="txt-wrap-sect pdd-rt-50">
                    <h2 className="mgn-bot-30">
                      All the nutrients your Furbaby needs
                    </h2>
                    <p className="para-txt">
                      We start one fabulous recipe that is suited to all breeds
                      and all life stages. Then we tailor how much food your
                      specific dog needs each day, based on their individual
                      needs
                    </p>
                    <div className="tick-list">
                      <ul>
                        <li>
                          You get a personalised feeding label telling you just
                          how much food to feed your dog at each meal.
                        </li>
                        <li>
                          Every meal is a complete, balanced diet packed with
                          all the nutrition your dog needs to thrive.
                        </li>
                        <li>
                          You just need to follow your personalised feeding
                          instructions.
                        </li>
                      </ul>
                    </div>
                    <Button
                      className="btn-def mgn-top-30"
                      onClick={() =>
                        navigate(
                          isLoggedIn() ? '/account/dashboard/' : '/getstarted/',
                        )
                      }
                    >
                      {isLoggedIn() ? 'Dashboard' : 'Build your feeding plan'}
                    </Button>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="op-img-wrap">
                    <OptimizedImage fileName="igr_img_st_2.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HowItWorksSection />
        </div>
      </div>
      <div className="testimonial-wrapper">
        <div className="container-fluid">
          <h2 className="text-center sc-lg">
            Loved by Thousands of New Zealand{' '}
            <span className="slash-or">Furbabies</span>
          </h2>
          <h2 className="text-center sc-sm">
            Loved by Thousands of
            <br /> New Zealand <span className="slash-or">Furbabies</span>
          </h2>
          <CardWrapper />
        </div>
      </div>
      <JoinFurFamily page_id="how_it_works_page_footer" />
    </MainLayout>
  );
};
